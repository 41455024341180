<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    :mapOptions="mapOptions"
  >
    <template #mapContent>
      <l-control
        v-if="locationType === 4"
        v-show="!editMode"
        position="topright"
      >
        <v-btn
          v-if="!editMode && canCreate"
          color="primary"
          @click="$emit('create')"
          v-text="createBtnText"
        />
      </l-control>
      <l-control
        v-show="showSaveButton"
        position="topright"
      >
        <v-btn
          v-if="!editingShape"
          small
          color="primary"
          class="mr-2"
          @click="$emit('save')"
          v-text="$t('base.save')"
        />
        <v-btn
          small
          color="secondary"
          @click="$emit('cancel')"
          v-text="$t('base.cancel')"
        />
      </l-control>
    </template>
  </map-base>
</template>

<script>
import store from '@/global/store'
import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt'
import { LControl } from 'vue2-leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'
import MapBase from '@/global/components/map/MapBase.vue'

export default {
  name: 'LocationsMap',

  components: {
    LControl,
    MapBase
  },

  props: {
    selectedLocations: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    locationType: {
      type: Number,
      default: 0
    },
    createBtnText: {
      type: String,
      default: ''
    },
    viewConfig: {
      type: Object,
      required: true
    },
    cancelShape: {
      type: Boolean,
      default: false
    },
    confirmShape: {
      type: Boolean,
      default: false
    },
    shapeType: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      mapOptions: {
        zoomSnap: 0.5
      },
      editableLayers: null,
      showSaveButton: false,
      editingShape: false,
      maxPolyPoints: 300,
      maxPolygonPoints: 15,
      mapStyle: {
        zIndex: 0
      },
      currentDrawType: '',
      newVertexes: [],
      shapeLimitedManually: false,
      customEditing: false,
      clickedConfirmShape: false
    }
  },

  computed: {
    canCreate () {
      if ('actions' in this.viewConfig) {
        return 'create' in this.viewConfig.actions
      }
      return false
    },

    drawLocal () {
      return {
        draw: {
          toolbar: {
            actions: {
              // title: 'Cancel drawing',
              text: this.$t('locations/map.cancel')
            },
            finish: {
              // title: 'Finish drawing',
              text: this.$t('locations/map.finish')
            },
            undo: {
              // title: 'Delete last point drawn',
              text: this.$t('locations/map.delete_last_point')
            },
            buttons: {
              polyline: this.$t('locations/map.polyline'),
              polygon: this.$t('locations/map.polygon'),
              rectangle: this.$t('locations/map.rectangle'),
              circle: this.$t('locations/map.circle'),
              marker: this.$t('locations/map.marker'),
              circlemarker: this.$t('locations/map.circlemarker')
            }
          },
          handlers: {
            circle: {
              tooltip: {
                start: this.$t('locations/map.circle_tooltip_start')
              },
              radius: this.$t('locations/map.radius')
            },
            circlemarker: {
              tooltip: {
                start: this.$t('locations/map.circlemarker_tooltip_start')
              }
            },
            marker: {
              tooltip: {
                start: this.$t('locations/map.marker_tooltip_start')
              }
            },
            polygon: {
              tooltip: {
                start: this.$t('locations/map.polygon_tooltip_start'),
                cont: this.$t('locations/map.polygon_tooltip_cont'),
                end: this.$t('locations/map.polygon_tooltip_end')
              }
            },
            polyline: {
              error: this.$t('locations/map.polyline_error'),
              tooltip: {
                start: this.$t('locations/map.polyline_tooltip_start'),
                cont: this.$t('locations/map.polyline_tooltip_cont'),
                end: this.$t('locations/map.polyline_tooltip_end')
              }
            },
            rectangle: {
              tooltip: {
                start: this.$t('locations/map.rectangle_tooltip_start')
              }
            },
            simpleshape: {
              tooltip: {
                end: this.$t('locations/map.simpleshape_tooltip_end')
              }
            }
          }
        },
        edit: {
          toolbar: {
            actions: {
              save: {
                // title: 'Save changes',
                text: this.$t('locations/map.save')
              },
              cancel: {
                // title: 'Cancel editing, discards all changes',
                text: this.$t('locations/map.cancel')
              },
              clearAll: {
                // title: 'Clear all layers',
                text: this.$t('locations/map.clear_all')
              }
            },
            buttons: {
              edit: this.$t('locations/map.edit_layers'),
              editDisabled: this.$t('locations/map.no_edit_layers'),
              remove: this.$t('locations/map.delete_layers'),
              removeDisabled: this.$t('locations/map.no_delete_layers')
            }
          },
          handlers: {
            edit: {
              tooltip: {
                text: this.$t('locations/map.edit_tooltip_text'),
                subtext: this.$t('locations/map.edit_tooltip_subtext')
              }
            },
            remove: {
              tooltip: {
                text: this.$t('locations/map.remove_tooltip_text')
              }
            }
          }
        }
      }
    },
    drawControlFull () {
      return new window.L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: this.editableLayers,
          poly: {
            allowIntersection: false
          }
        },
        draw: {
          polyline: this.polylineDrawSettings(),
          polygon: true,
          rectangle: true,
          circle: true,
          marker: true,
          circlemarker: false
        }
      })
    },
    drawControlEditOnly () {
      return new window.L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: this.editableLayers,
          poly: {
            allowIntersection: false
          }
        },
        draw: false
      })
    }
  },

  watch: {
    selectedLocations (locations) {
      if (this.editableLayers && locations.length) {
        this.editableLayers.clearLayers()
        this.removeDrawControls()
        this.showSaveButton = false
      }
      else {
        this.editableLayers.clearLayers()
      }

      locations.forEach(location => {
        location.data.includes('CIRCLE')
          ? this.handleCircleData(location)
          : this.handleOtherShapes(location)

        setTimeout(() => {
          this.$refs?.mapBase?.$refs?.map.mapObject.fitBounds(this.editableLayers.getBounds(), { padding: [5, 5] })
        }, 150)
      })
    },

    editMode (value) {
      if (value) {
        this.$refs?.mapBase?.$refs?.map.mapObject.addControl(this.drawControlFull)

        if (this.editableLayers) {
          this.editableLayers.clearLayers()
          this.addFullDrawControls()
        }
      }
      else {
        if (this.editableLayers) {
          this.editableLayers.clearLayers()
          this.removeDrawControls()
        }
      }

      this.showSaveButton = value
    },

    editData (newValue) {
      if (newValue) {
        this.editableLayers.clearLayers()

        newValue.data.includes('CIRCLE')
          ? this.handleCircleData(newValue)
          : this.handleOtherShapes(newValue)

        this.activateEditControls()

        setTimeout(() => {
          this.$refs?.mapBase?.$refs?.map.mapObject.fitBounds(this.editableLayers.getBounds(), { padding: [5, 5] })
        }, 150)
      }
    },

    cancelShape (val) {
      if (val) {
        this.$emit('input', null)
        if (this.editableLayers) {
          this.editableLayers.clearLayers()
          this.addFullDrawControls()
        }
        this.newVertexes = []
        this.customEditing = false
        this.$emit('reset-cancel-shape')
      }
    },

    confirmShape (val) {
      if (val) {
        this.clickedConfirmShape = true
        if (this.editableLayers) {
          this.editableLayers.clearLayers()
          this.addFullDrawControls()
          this.activateEditControls()
        }

        // Create a new shape if the current one exceeds the allowed point limit (polygon or polyline)
        const newShape = this.shapeType === 'polygon' ? new window.L.Draw.Polygon(this.$refs?.mapBase?.getMapObject(), this.drawControlFull._toolbars.draw.options.polygon) : new window.L.Draw.Polyline(this.$refs?.mapBase?.getMapObject(), this.drawControlFull._toolbars.draw.options.polyline)

        // Enable current shape edit
        newShape.enable()
        this.shapeLimitedManually = true

        if (this.customEditing) {
          const splicedVertexes = this.newVertexes.splice(this.shapeType === 'polygon' ? this.maxPolygonPoints : this.maxPolyPoints)

          splicedVertexes.forEach(vertex => {
            newShape.addVertex(vertex)
          })
        }
        else {
          // Add vertexes to the new shape
          this.newVertexes.forEach(vertex => {
            newShape.addVertex(vertex)
          })

          // Delete the last vertex from the shape as it exceeds the maximum allowed number of vertex points
          newShape.deleteLastVertex()
        }

        // Complete shape
        newShape.completeShape()

        newShape.disable()
        this.newVertexes = []
        this.customEditing = false
        this.$emit('reset-confirm-shape')
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.initMapDrawControls()
      this.fixMissingIcons()
    })
  },

  methods: {
    polylineDrawSettings () {
      return {
        allowIntersection: false,
        showArea: true
      }
    },

    emitInputEvent (layer) {
      if (layer instanceof window.L.Circle) {
        const { lat, lng } = layer.getLatLng()
        const radius = layer.getRadius()
        const circleData = `CIRCLE (${lat} ${lng}, ${radius})`
        this.$emit('input', circleData)
      }
      else {
        window.L.geoJSON(layer.toGeoJSON(), {
          coordsToLatLng: function (coords) {
            // latitude , longitude, altitude
            return new window.L.LatLng(coords[0], coords[1], coords[2])
          },
          onEachFeature: (feature, layer) => {
            this.$emit('input', geojsonToWKT(layer.toGeoJSON().geometry))
          }
        })
      }
    },

    updateDataType (layer) {
      let dataType = null

      if (layer instanceof window.L.Polyline) {
        dataType = 2
      }
      if (layer instanceof window.L.Polygon) {
        dataType = 1
      }
      if (layer instanceof window.L.Circle) {
        dataType = 3
      }
      if (layer instanceof window.L.Marker) {
        dataType = 4
      }

      this.$emit('update-type', dataType)
    },

    handleCircleData (location) {
      const [latLongPart, radiusPart] = location.data.split(',')

      const coordinates = latLongPart.replace('CIRCLE', '')
        .replace('(', '')
        .trim()
        .split(' ')
        .map(value => parseFloat(value))

      const radius = parseFloat(radiusPart.replace(')', '').trim())

      let circle = null
      let marker = null

      // Check if location type is point
      if (location.data_type.id === 4) {
        marker = new window.L.Marker(coordinates)
      }

      if (location.data_type.id !== 4 || !this.editMode) {
        circle = new window.L.Circle(coordinates, { radius })
        circle.setStyle({ color: location.color })
      }

      setTimeout(() => {
        if (circle) {
          this.editableLayers.addLayer(circle)
          this.updateDataType(circle)
          this.emitInputEvent(circle)
        }
        if (marker) {
          this.editableLayers.addLayer(marker)
          this.updateDataType(marker)
          this.emitInputEvent(marker)
        }
      }, 100)
    },

    handleOtherShapes (location) {
      const geoJsonData = wktToGeoJSON(location.data)

      window.L.geoJson(geoJsonData, {
        coordsToLatLng: function (coords) {
          // latitude , longitude, altitude
          return new window.L.LatLng(coords[0], coords[1], coords[2])
        },
        onEachFeature: (feature, layer) => {
          if (!(layer instanceof window.L.Marker)) {
            layer.setStyle({ color: location.color })
          }

          setTimeout(() => {
            this.editableLayers.addLayer(layer)
            this.updateDataType(layer)
            this.emitInputEvent(layer)
          }, 100)
        }
      })
    },

    limitPolylinePoints (layer, layerType) {
      // Only limit number of points for polyline and polygon shape types
      if (layerType === 'polyline' || layerType === 'polygon') {
        const layerLatLngs = layerType === 'polygon'
          ? layer.getLatLngs()[0]
          : layer.getLatLngs()

        const shapeMaxPoints = layerType === 'polyline' ? this.maxPolyPoints : this.maxPolygonPoints
        if (layerLatLngs.length > shapeMaxPoints) {
          // Remove redundant points from the shape
          return this.deleteLastVertexes(layerType, shapeMaxPoints, layer, layerLatLngs)
        }
      }

      return false
    },

    deleteLastVertexes (layerType, maxShapePoints, layer, layerLatLngs) {
      // Create and enable draw handler, needed for deleting last vertex functionality
      const drawPolyline = layerType === 'polyline' ? new window.L.Draw.Polyline(this.$refs?.mapBase?.$refs?.map.mapObject, this.drawControlFull._toolbars.draw.options.polyline) : new window.L.Draw.Polygon(this.$refs?.mapBase?.$refs?.map.mapObject, this.drawControlFull._toolbars.draw.options.polygon)

      drawPolyline.enable()

      // Remove extra vertices from the shape
      const extraVertices = layerLatLngs.splice(maxShapePoints)
      extraVertices.forEach(() => {
        drawPolyline.deleteLastVertex()
      })

      // Complete the shape
      drawPolyline.completeShape()

      // Disable draw handler
      drawPolyline.disable()

      // Redraw the shape on the map (needed for edit functionality)
      layer.redraw()

      // Show warning message
      store.dispatch('base/notifications/push', this.$t('locations/map.limit_polyline').replace(':limit', maxShapePoints))

      return true
    },

    getLayerType (layer) {
      if (layer instanceof window.L.Circle) {
        return 'circle'
      }

      if (layer instanceof window.L.Marker) {
        return 'marker'
      }

      if ((layer instanceof window.L.Polyline) && !(layer instanceof window.L.Polygon)) {
        return 'polyline'
      }

      if ((layer instanceof window.L.Polygon) && !(layer instanceof window.L.Rectangle)) {
        return 'polygon'
      }

      if (layer instanceof window.L.Rectangle) {
        return 'rectangle'
      }
    },

    initMapDrawControls () {
      const map = this.$refs?.mapBase?.$refs?.map.mapObject
      this.editableLayers = new window.L.FeatureGroup()
      this.editableLayers.addTo(map)

      window.L.drawLocal = this.drawLocal
      map.on(window.L.Draw.Event.CREATED, (e) => {
        const layer = e.layer
        const layerType = e.layerType

        if (!this.shapeLimitedManually) {
          this.limitPolylinePoints(layer, layerType)
        }
        this.shapeLimitedManually = false

        this.updateDataType(layer)
        this.emitInputEvent(layer)

        this.editableLayers.addLayer(layer)
        this.activateEditControls()
        this.newVertexes = []
        this.customEditing = false
        this.clickedConfirmShape = false
      })

      map.on(window.L.Draw.Event.EDITED, (e) => {
        let shapeChanged = false

        e.layers.eachLayer(layer => {
          const layerType = this.getLayerType(layer)

          shapeChanged = this.limitPolylinePoints(layer, layerType)

          this.updateDataType(layer)
          this.emitInputEvent(layer)
        })

        if (!shapeChanged) {
          this.$nextTick(() => this.$emit('save'))
        }
      })

      map.on(window.L.Draw.Event.EDITSTART, (e) => {
        this.editingShape = true
      })

      map.on(window.L.Draw.Event.EDITSTOP, (e) => {
        this.editingShape = false
      })

      map.on(window.L.Draw.Event.DELETED, (e) => {
        this.$emit('input', null)
        this.addFullDrawControls()
      })

      map.on(window.L.Draw.Event.TOOLBARCLOSED, () => {
        const existingMapLayers = this.editableLayers ? this.editableLayers.getLayers().length : 0
        if (!existingMapLayers && this.newVertexes && !this.clickedConfirmShape) {
          this.newVertexes = []
          this.customEditing = false
          this.clickedConfirmShape = false
        }
      })

      map.on(window.L.Draw.Event.DRAWSTART, (e) => {
        if ((this.currentDrawType === 'polygon' && e.layerType !== 'polygon') || (this.currentDrawType === 'polyline' && e.layerType !== 'polyline')) {
          this.newVertexes = []
        }
        this.currentDrawType = e.layerType
        if (e.layerType === 'polygon') {
          this.$emit('show-polygon-max-points-message', this.$t('locations/location.help_text') + '<br>' + this.$t('locations/map.limit_polygon_warning_message').replace(':limit', this.maxPolygonPoints))
        }
        else if (e.layerType === 'polyline') {
          this.$emit('show-polygon-max-points-message', this.$t('locations/location.help_text') + '<br>' + this.$t('locations/map.limit_polyline_warning_message').replace(':limit', this.maxPolyPoints))
        }
        else {
          this.$emit('show-polygon-max-points-message', this.$t('locations/location.help_text'))
        }
      })

      map.on(window.L.Draw.Event.EDITVERTEX, (e) => {
        this.checkLimitOfAddedVertexesAndShowWarningDialog(e, 'edit')
        this.customEditing = true
      })

      map.on(window.L.Draw.Event.DRAWVERTEX, (e) => {
        this.checkLimitOfAddedVertexesAndShowWarningDialog(e)
      })
    },

    fixMissingIcons () {
      delete window.L.Icon.Default.prototype._getIconUrl

      window.L.Icon.Default.mergeOptions({
        iconRetinaUrl: '/img/icons/icon_sensor_start.svg',
        iconUrl: '/img/icons/icon_sensor_start.svg',
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
    },

    checkLimitOfAddedVertexesAndShowWarningDialog (e, type = '') {
      const createdVertexes = e && e.layers ? e.layers.getLayers() : []

      if (!createdVertexes || !createdVertexes.length) {
        return
      }

      if (type === 'edit') {
        this.newVertexes = []
        createdVertexes.forEach(vertex => {
          this.newVertexes.push(vertex._latlng)
        })
      }
      else {
        const lastVertexPoint = createdVertexes[createdVertexes.length - 1]
        // Push every new added vertex point to this array to be able to redraw a new shape later when exceeding limit of points of drawing shape
        if (lastVertexPoint && lastVertexPoint._latlng) {
          this.newVertexes.push(lastVertexPoint._latlng)
        }
      }

      if (this.currentDrawType === 'polygon') {
        if (createdVertexes.length > this.maxPolygonPoints) {
          if (!this.shapeLimitedManually) {
            this.$emit('show-exceed-limit-dialog', {
              type: 'polygon',
              limit: this.maxPolygonPoints
            })
          }
        }
      }
      else if (this.currentDrawType === 'polyline') {
        if (createdVertexes.length > this.maxPolyPoints) {
          if (!this.shapeLimitedManually) {
            this.$emit('show-exceed-limit-dialog', {
              type: 'polyline',
              limit: this.maxPolyPoints
            })
          }
        }
      }
    },

    activateEditControls () {
      const map = this.$refs?.mapBase?.$refs?.map.mapObject
      this.drawControlFull.remove(map)
      this.drawControlEditOnly.addTo(map)
    },

    addFullDrawControls () {
      const map = this.$refs?.mapBase?.$refs.map.mapObject

      if (this.editableLayers.getLayers().length === 0) {
        this.drawControlEditOnly.remove(map)
        this.drawControlFull.addTo(map)
        this.removeTitleFromControls()
      }
    },

    removeDrawControls () {
      const map = this.$refs?.mapBase?.$refs?.map.mapObject
      this.drawControlFull.remove(map)
      this.drawControlEditOnly.remove(map)
    },

    removeTitleFromControls () {
      document.querySelectorAll('.leaflet-draw a:not(.leaflet-disabled)').forEach(element => {
        element.removeAttribute('title')
      })
    }
  }
}
</script>

<style>
.leaflet-touch .leaflet-bar a {
  position: relative;
}

.leaflet-draw a .sr-only {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  padding: 5px 10px;
  margin: 0;
  z-index: 10;
  transform: translateX(calc(-100% - 5px)) translateY(3px);
  background: #fff;
  color: #333;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  clip: unset;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.leaflet-draw a.leaflet-disabled .sr-only,
.leaflet-draw a.leaflet-draw-toolbar-button-enabled .sr-only {
  visibility: hidden;
}
</style>
