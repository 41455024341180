<template>
  <div class="fill-height">
    <v-alert
      v-if="editMode"
      type="info"
      class="mb-0 py-3 rounded-0"
      v-html="alertText"
    />
    <div class="locations-wrapper">
      <div class="data-table-container">
        <index-view
          view-name="locations"
          :module="module"
          :model="model"
          :list="list"
          :pagination="pagination"
          :view-config="viewConfig"
          :fetch="fetch"
          :additional-filters="additionalFilters"
          :additional-form-data="additionalFormData"
          :show-edit-dialog="showEditDialog"
          :reset-selection="resetSelection"
          :reset-form="resetForm"
          :container-classes="containerClasses"
          :show-overlay="editMode"
          prevent-default-create
          selectable-items
          no-create
          :no-actions="locationType !== 4"
          @selection-updated="handleLocationSelection"
          @form-saved="handleFormSave"
          @edit-item="handleEdit"
          @create-item="handleCreate"
          @item-deleted="handleLocationSelection"
          @close-edit="showEditDialog = false"
        />
      </div>
      <div
        class="map-container"
      >
        <locations-map
          class="locations-map fill-height"
          :edit-data="editLocationData"
          :edit-mode="editMode"
          :selected-locations="selectedLocations"
          :location-type="locationType"
          :create-btn-text="viewConfig.create_button"
          :view-config="viewConfig"
          :cancel-shape="cancelShape"
          :confirm-shape="confirmShape"
          :shape-type="limitExceedDialogType"
          @input="handleMapDataChange"
          @cancel="handleCancel"
          @create="handleCreate"
          @save="handleSave"
          @show-polygon-max-points-message="alertMessage => updateAlertText(alertMessage)"
          @update-type="dataType = $event"
          @show-exceed-limit-dialog="setShowLimitExceedDialog"
          @reset-cancel-shape="cancelShape = false"
          @reset-confirm-shape="confirmShape = false"
        />
      </div>
    </div>
    <v-dialog
      v-model="showLimitExceedDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '80%'"
      persistent
    >
      <v-card>
        <v-card-title
          style="background-color: #eeeeee; font-size: 1.2rem;"
        >
          <v-icon
          >
            mdi-alert
          </v-icon>
          <span
            v-html="limitExceedDialogTitle"
            class="ml-3"
          />
        </v-card-title>
        <v-card-text>
          <span v-html="limitExceedDialogMessage" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="error"
            @click="setCancelShape"
          >
            {{ $t('locations/map.limit_exceed_dialog_cancel_button')}}
          </v-btn>
          <v-btn
            class="primary"
            @click="setConfirmShape"
          >
            {{ $t('locations/map.limit_exceed_dialog_confirm_button')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'
import LocationsMap from '../components/LocationsMap'

const { mapGetters, mapActions } = createNamespacedHelpers('locations/locations')
export default {
  name: 'LocationsIndexView',
  components: {
    LocationsMap,
    'index-view': indexView
  },
  props: {
    module: {
      type: String,
      default: 'locations'
    },
    model: {
      type: String,
      default: 'locations'
    }
  },
  data () {
    return {
      selectedLocations: [],
      dataType: null,
      locationData: null,
      editLocationData: null,
      showEditDialog: false,
      resetSelection: false,
      resetForm: false,
      editMode: false,
      baseAlertText: this.$t('locations/location.help_text'),
      alertText: this.$t('locations/location.help_text'),
      limitExceedDialogLimitParameter: '',
      limitExceedDialogType: '',
      showLimitExceedDialog: false,
      cancelShape: false,
      confirmShape: false,
      limitExceedDialogTitle: '',
      limitExceedDialogMessage: ''
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'viewConfig']),

    locationType () {
      const routeType = this.$route.path.split('/locations/')[1]
      const locationTypeMap = {
        city: 1,
        region: 2,
        country: 3,
        user_defined: 4,
        city_district: 5
      }

      return locationTypeMap[routeType]
    },

    additionalFilters () {
      return {
        type_id: this.locationType
      }
    },

    additionalFormData () {
      return {
        data: this.locationData,
        data_type_id: this.dataType
      }
    },

    containerClasses () {
      return ['pt-0', 'pr-0']
    }
  },
  methods: {
    ...mapActions(['fetch']),

    updateAlertText (alertMessage) {
      this.alertText = alertMessage
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },

    handleLocationSelection (locations) {
      if (Array.isArray(locations) && locations.length !== 1) {
        this.locationData = null
      }
      if (locations.length && this.editMode) {
        this.editMode = false
      }
      this.selectedLocations = locations
      this.resetSelection = false
    },

    handleMapDataChange (data) {
      if (this.selectedLocations.length !== 1 && !this.editMode) {
        this.locationData = null
      }
      else this.locationData = data
    },

    handleEdit (item) {
      this.locationData = null
      this.editLocationData = item
      this.editMode = true
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      this.resetSelection = true
    },

    handleCreate () {
      this.locationData = null
      this.alertText = this.baseAlertText
      this.editMode = true
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      this.resetSelection = true
    },

    setShowLimitExceedDialog (parameters) {
      if (parameters && parameters.type && parameters.limit) {
        this.showLimitExceedDialog = true
        this.limitExceedDialogLimitParameter = parameters.limit
        this.limitExceedDialogType = parameters.type

        this.limitExceedDialogTitle = this.limitExceedDialogType === 'polygon' ? this.$t('locations/map.limit_polygon_warning_message').replace(':limit', this.limitExceedDialogLimitParameter) : this.$t('locations/map.limit_polyline_warning_message').replace(':limit', this.limitExceedDialogLimitParameter)

        this.limitExceedDialogMessage = this.$t('locations/map.limit_exceed_dialog_cancellation_message').replace(':limit', this.limitExceedDialogLimitParameter).replace(':type', this.limitExceedDialogType) + '<br>' + this.$t('locations/map.limit_exceed_dialog_confirmation_message').replace(':limit', this.limitExceedDialogLimitParameter).replace(':type', this.limitExceedDialogType)
      }
    },

    setCancelShape () {
      this.showLimitExceedDialog = false
      this.cancelShape = true
      this.locationData = null
      this.editLocationData = null
      this.resetFormData()
    },

    setConfirmShape () {
      this.showLimitExceedDialog = false
      this.confirmShape = true
    },

    handleFormSave () {
      this.editMode = false
      this.editLocationData = null
      this.showEditDialog = false
    },

    handleSave () {
      if (!this.locationData) {
        return
      }
      this.showLimitExceedDialog = false
      this.showEditDialog = true
    },

    handleCancel () {
      this.dataType = null
      this.cancelShape = false
      this.confirmShape = false
      this.showLimitExceedDialog = false
      this.locationData = null
      this.editLocationData = null
      this.editMode = false
      this.showEditDialog = false
      this.selectedLocations = []
      this.resetSelection = true
      this.resetFormData()
    },

    resetFormData () {
      this.resetForm = true

      setTimeout(() => {
        this.resetForm = false
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.locations-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto;
  padding: 1rem 0 0 0;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  .map-container {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 1rem 1rem 1rem;
    .locations-map {
      height: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .locations-wrapper {
    flex-direction: column;
    .data-table-container {
      padding-right: 1rem;
    }
    .map-container {
      .locations-map {
        height: 30rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .company-scope {
    flex-basis: unset;
    max-width: 45%;
  }
}
</style>
